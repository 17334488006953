<template>
	<div class="dashboard">
		<h2>{{ msg }}</h2>
		<select id="semesters" v-if="classes">
			<option
				v-for="(c, semester) in classes"
				:key="c.term_id"
				:value="semester"
			>
				{{ semester }}
			</option>
		</select>
		<ul id="courses" v-if="classes">
			<li
				v-for="subject in classes[selectedClass]['subjects']"
				:key="subject.term_id"
			>
				<router-link
					class="subject-link"
					:to="{
						name: 'Subject',
						params: {
							semester: slugify(selectedClass),
							subject: slugify(subject.name),
							id: subject.term_id,
						},
					}"
				>
					{{ subject.name }}
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
import ClassService from '@/services/ClassService.js'

export default {
	name: 'Dashboard',
	props: {
		msg: String,
	},
	data() {
		return {
			classes: null,
			selectedClass: null,
		}
	},
	computed: {
		slugify() {
			return (value) => {
				return value.replace(/\s+/g, '-').toLowerCase()
			}
		},
	},
	created() {
		ClassService.getClasses()
			.then((response) => {
				this.classes = response.data
				this.selectedClass = Object.keys(response.data)[0]
				// console.log(response.data, this.selectedClass)
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
	margin: 40px 0 0;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
a {
	color: #42b983;
}

#courses {
	margin-top: 50px;
}

a.subject-link {
	padding: 20px;
	cursor: pointer;
	border: 1px solid #39495c;
	margin-bottom: 18px;
	text-decoration: none;
	font-size: 50px;
	font-weight: bold;

	&:hover {
		transform: scale(1.01);
		box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
	}
}
</style>
