import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/:semester/:subject/:id',
		name: 'Subject',
		props: true,
		component: () =>
			// route level code-splitting (Needed for links not available on page load)
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			import(/* webpackChunkName: "about" */ '../views/Subject.vue'),
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router
