import axios from 'axios'

const apiClient = axios.create({
	baseURL: 'https://studentseating.adaptixstudio.com/wp-json/adaptix/v1/',
	withCredentials: false,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
})

export default {
	getClasses() {
		return apiClient.get('/classes')
	},
	getStudents(id) {
		return apiClient.get(`/classes/${id}`)
	},
	saveStudentList(id, studentListBasedOnLayout) {
		return apiClient.put(`/classes/${id}`, {
			studentListBasedOnLayout: studentListBasedOnLayout,
		})
	},
}
