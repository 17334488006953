<template>
	<div id="nav">
		<h1>
			<router-link :to="{ name: 'Home' }">Student Seating Chart</router-link>
		</h1>
	</div>
	<router-view />
</template>

<style lang="scss">
html,
body {
	margin: 0;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nav {
	padding: 0;
	margin-bottom: 50px;

	h1 {
		margin: 0;
	}

	a {
		font-weight: bold;
		color: white;
		text-decoration: none;
		display: block;
		padding: 10px 0;

		// &.router-link-exact-active {
		// 	color: #42b983;
		// }
	}

	background-color: #f36161;
}
</style>
